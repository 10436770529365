<template>
	<section class="permission">
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<el-form :inline="true" :model="filters">
				<span>
					<el-form-item>
						<el-input v-model.trim="filters.keyword" placeholder="输入内容"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getList(0)" icon="el-icon-search">搜索</el-button>
					</el-form-item>
				</span>
			</el-form>
		</el-col>

		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">
			<el-table-column prop="mobile" label="手机号码" width="125">
			</el-table-column>
			<el-table-column prop="code" label="验证码" width="100">
			</el-table-column>			
			<el-table-column prop="code" label="类型" width="100">
				<template slot-scope="scope">
					<span v-if="scope.row.type == 1">短信</span><span v-else>语音</span>
				</template>
			</el-table-column>			
			<el-table-column prop="code" label="通道" width="100">
				<template slot-scope="scope">
					<span>通道{{scope.row.channel}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="ip" label="ip地址" width="160">
			</el-table-column>
			<el-table-column prop="event" label="用户操作" width="160">
			</el-table-column>
			<el-table-column prop="createtime" label="发送时间" sortable width="200">
			</el-table-column>
			<el-table-column prop="result" label="发送结果">
			</el-table-column>
		</el-table>
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<!--分页-->
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="page"
					:page-sizes="pagesizes"
					:page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total">
			</el-pagination>
		</el-col>

	</section>
</template>

<script>
	import { smsList } from '../../api/api';
	export default {
		data() {
			return {
				filters: {
					keyword: ''
				},
				listData: [],
				//分页
				total: 0,
				page: 1,
				pagesize: 10,//每页条数
				pagesizes: [10, 20, 50, 100],//可选条数
				listLoading: false,
			}
		},
		methods: {
			//分页
			handleSizeChange(val) {
				this.pagesize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			//获取用户列表
			getList(val) {
                if(val == '0') this.page = 1
				let para = {
					page: this.page,
					keyword: this.filters.keyword,
					pagesize: this.pagesize	//分页条数
				};
				this.listLoading = true;
				smsList(para).then((res) => {
					this.listLoading = false;
					if(res.code == 1){
						this.total = res.data.count;
						this.listData = res.data.data;
						this.listLoading = false;
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
		},
		mounted() {
			//获取列表数据
			this.getList();
		}
	}

</script>

<style lang="scss">
	@import "../../styles/permission.scss";
</style>